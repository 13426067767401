import React, { useState } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/solid";

const errorMessages = [
  "No es tan difícil",
  "Vamos, tu puedes",
  "Continúa buscando",
];
function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}

const TwoQuestionsForm = ({
  validateResponse1,
  question1,
  validateResponse2,
  question2,
  onSuccess,
}) => {
  const [error, setError] = useState(false);
  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const handleChangeInput = (e) => {
    setError(false);
    setInput1(e.target.value);
  };
  const handleChangeInput2 = (e) => {
    setError(false);
    setInput2(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log();
    if (validateResponse1(input1) && validateResponse2(input2)) {
      setError(false);
      onSuccess(e);
    } else {
      setError(true);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="text" className="block text-lg">
        {question1}
      </label>
      <div className="mt-4 relative rounded-md shadow-sm border">
        <input
          type="text"
          name="text"
          id="text"
          className="block w-full my-1 pr-10 pl-2 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md leading-8"
          placeholder="respuesta"
          // defaultValue="adamwathan"
          value={input1}
          onChange={handleChangeInput}
          aria-invalid="true"
        />
        {/*{error && (*/}
        {/*  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">*/}
        {/*    <ExclamationCircleIcon*/}
        {/*      className="h-5 w-5 text-red-500"*/}
        {/*      aria-hidden="true"*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
      <label htmlFor="text" className="block text-lg mt-4">
        {question2}
      </label>
      <div className="mt-4 relative rounded-md shadow-sm border">
        <input
          type="text"
          name="text"
          id="text"
          className="block w-full my-1 pr-10 pl-2 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md leading-8"
          placeholder="respuesta"
          // defaultValue="adamwathan"
          value={input2}
          onChange={handleChangeInput2}
          aria-invalid="true"
        />
        {/*{error && (*/}
        {/*  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">*/}
        {/*    <ExclamationCircleIcon*/}
        {/*      className="h-5 w-5 text-red-500"*/}
        {/*      aria-hidden="true"*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>

      <p
        className={`${!error && "invisible"} mt-1 text-sm text-red-600 py-2`}
        id="email-error"
      >
        {errorMessages[getRandomInt(0, errorMessages.length)]}
      </p>

      <button
        type="submit"
        className=" mt-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Ir a la prueba final
      </button>
    </form>
  );
};

export default TwoQuestionsForm;
